import React, {Component} from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import {CSpinner} from "@coreui/react-pro";
import Login from "./views/pages/login/Login";

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const isAccessTokenValid = () => {
    if (!localStorage.getItem('accessToken') || localStorage.getItem('accessToken') === 'undefined') {
        return false
    }

    const accessTokenCreated = Number(localStorage.getItem('accessTokenCreated'))
    const accessTokenExpiration = Number(localStorage.getItem('accessTokenExpiration'))
    const expiration = accessTokenExpiration + accessTokenCreated
    /*
    console.log('token created: ', accessTokenCreated)
    console.log('token valid for: ', accessTokenExpiration)
    console.log('token expiration: ', expiration)
    console.log('now: ', new Date().getTime())
    console.log('token valid: ', new Date().getTime() < expiration)
    */
    if (new Date().getTime() < expiration) {
        return true
    }

    return false
}

const isAuthenticated = () => {
    const access = localStorage.getItem('accessToken')

    if (access !== null && isAccessTokenValid()) {
        return true
    }

    return false;
}
/*
const PublicRoute = ({...rest}) => (
    <Route {...rest} render={(props) => (
        !isAuthenticated() ? <DefaultLayout {...props}/> : <Redirect to={'/'}/>
    )}/>
)
*/
const ProtectedRoute = ({...rest}) => (
    <Route {...rest} render={(props) => (
        isAuthenticated() ? <DefaultLayout {...props}/> : <Redirect to={'/login'}/>
    )}/>
)

class App extends Component {
    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={<CSpinner color="primary" />}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props}/>}/>
                        <ProtectedRoute path="/" name="Home"/>
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;
