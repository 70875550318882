import React, {useState} from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
    CAlert,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {Redirect} from "react-router-dom";
import {getToken} from "../../../utils/api/_client";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [notifications, setNotifications] = useState()
    const [authenticated, setAuthenticated] = useState(false)

    const loginUser = () => {
        setLoading(true)
        getToken({email: email, password: password})
            .then((response) => {
                const {data} = response
                localStorage.setItem('accessToken', data.token)
                localStorage.setItem('accessTokenExpiration', 24 * 60 * 60 * 1000)
                localStorage.setItem('accessTokenCreated', new Date().getTime())
                setAuthenticated(true)
            })
            .catch((e) => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('accessTokenExpiration')
                localStorage.removeItem('accessTokenCreated')

                if (e.errors) {
                    const {errors} = e
                    const nlist = []
                    errors.forEach((err) => {
                        nlist.push(<CAlert key={err.id} color={'danger'}>{err.title}</CAlert>)
                    })
                    setNotifications(nlist)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updateEmail = (e) => {
        setEmail(e.target.value)
    }

    const updatePassword = (e) => {
        setPassword(e.target.value)
    }

    if (authenticated) {
        return (<Redirect to={'/dashboard'}/>)
    }

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="4">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        {notifications}
                                        <CInputGroup className="mb-3">
                                            <CInputGroupText>
                                                <CIcon icon="cil-user"/>
                                            </CInputGroupText>
                                            <CFormInput type="text" placeholder="E-mail" autoComplete="email"
                                                        value={email}
                                                        onChange={updateEmail}/>
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupText>
                                                <CIcon icon="cil-lock-locked"/>
                                            </CInputGroupText>
                                            <CFormInput type="password" placeholder="Password"
                                                        autoComplete="current-password" value={password}
                                                        onChange={updatePassword}/>
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton disabled={loading} color="primary" className="px-4"
                                                         onClick={loginUser}>Login</CButton>
                                            </CCol>
                                            <CCol xs="6" className="text-right">
                                                {/*
                                                <CButton color="link" className="px-0">Forgot password?</CButton>
                                                */}
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default Login
