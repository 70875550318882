import axios from "axios";
import {toast} from "react-toastify";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
})

client.interceptors.response.use((response) => {
    return response
}, (error) => {
    switch (error.response.status) {
        case 500:
            error.response.data.errors.forEach((e) => {
                toast.error(e.title)
            })
            break
        case 401:
            localStorage.removeItem('accessToken')
            localStorage.removeItem('accessTokenExpiration')
            localStorage.removeItem('accessTokenCreated')
            error.response.data.errors.forEach((e) => {
                toast.error(e.title)
            })
            break
        default:
            toast.error(error)
    }

    return Promise.reject(error)
})

export async function getToken(data = {}) {
    return client.post('/v1/token', data,{
        withCredentials: false,
    })
}

export async function get(path, params = {}) {
    let headers = {}

    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    return client.get( path, {
        withCredentials: false,
        headers: headers,
        params: params
    })
}

export async function post(path, data = {}, headers = {}) {
    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    return client.post(path, data,{
        withCredentials: false,
        headers: headers,
    })
}

export async function patch(path, data = {}) {
    let headers = {}

    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    return client.patch(path, data,{
        withCredentials: false,
        headers: headers,
    })
}

export async function remove(path) {
    let headers = {}

    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    return client.delete(path,{
        withCredentials: false,
        headers: headers,
    })
}

/*
export async function get(path, options = {}) {
    let headers = {
        'Content-Type': 'application/json',
    }

    if (options.headers !== undefined) {
        headers = {...headers, ...options.headers}
    }

    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    const response = await fetch(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH + path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'omit',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })

    return response.json()
}

export async function post(path, options = {}, data = {}) {
    let headers = {
        'Content-Type': 'application/json',
    }

    if (options.headers !== undefined) {
        headers = {...headers, ...options.headers}
    }

    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    const response = await fetch(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH + path, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'omit',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })

    if (response.ok) {
        const json = await response.json()

        return Promise.resolve(json)
    }

    const json = await response.json()

    return Promise.reject(json)
}

export async function patch(path, options = {}, data = {}) {
    let headers = {
        'Content-Type': 'application/json',
    }

    if (options.headers !== undefined) {
        headers = {...headers, ...options.headers}
    }

    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    const response = await fetch(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH + path, {
        method: 'PATCH',
        cache: 'no-cache',
        credentials: 'omit',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })

    return response.json()
}

export async function remove(path, options = {}) {
    let headers = {
        'Content-Type': 'application/json',
    }

    if (options.headers !== undefined) {
        headers = {...headers, ...options.headers}
    }

    if (localStorage.getItem('accessToken')) {
        headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }

    const response = await fetch(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH + path, {
        method: 'DELETE',
        cache: 'no-cache',
        credentials: 'omit',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    })

    return response.json()
}
*/
